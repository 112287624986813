





















































































import Vue from "vue";

import rules from "@/components/account/rules";
import { notifyError, notifyInfo } from "@/components/Notification";

import { getAPI } from "@/api/axios-base";

export default Vue.extend({
  name: "btn-create-renewal-status",
  props: {
    expanded: Boolean,
  },
  data() {
    return {
      dialog: false,
      formValid: false,

      rules,

      loading: false,
      form: {
        name: "",
        color: "",
        default: false,
      },
    };
  },
  methods: {
    create(): void {
      this.loading = true;
      this.form.name = this.form.name;
      getAPI
        .post("renewall-status/create", this.form)
        .then(() => {
          this.loading = false;
          (this as any).$refs.form.resetValidation();
          notifyInfo(`The Renewal status has been created successfully`);
          this.resetform();
          this.$emit("created");
          this.close();
        })
        .catch((error: any) => {
          this.loading = false;
          this.dialog = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    resetform() {
      this.form = {
        name: "",
        color: "",
        default: false,
      };
    },
    close() {
      this.dialog = false;
      this.resetform();
    },
  },
});
